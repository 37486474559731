export default [
  {
    component: 'CNavTitle',
    name: 'Analytics',
  },
  {
    component: 'CNavItem',
    name: 'Productos',
    to: '/dashboard/products',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Favoritos',
    to: '/dashboard/tracing',
    icon: 'cil-star',
  },
  {
    component: 'CNavTitle',
    name: 'Information',
  },
  {
    component: 'CNavItem',
    name: 'Notificaciones',
    to: '/dashboard/notifications',
    icon: 'cil-bell',
    soon: true,
    badge: {
      text: 'Soon',
      color: 'info'
    }
  },
]
