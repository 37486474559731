import axios from 'axios'
import { serverConfig } from '@/../config.js'
import FirebaseAuth from '@/firebase/Auth'

const api = axios.create({
  baseURL: serverConfig.protocol + '://' + serverConfig.server,
})

api.interceptors.request.use(async (config) => {
  const user = FirebaseAuth.currentUser;

  if (user) {
    const token = await user.getIdToken()
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default api
