/* BRANDS */
// import {
//   cibFacebook,
//   cibTwitter,
//   cibLinkedin,
//   cibFlickr,
//   cibTumblr,
//   cibXing,
//   cibGithub,
//   cibGoogle,
//   cibStackoverflow,
//   cibYoutube,
//   cibDribbble,
//   cibInstagram,
//   cibPinterest,
//   cibVk,
//   cibYahoo,
//   cibBehance,
//   cibReddit,
//   cibVimeo,
//   cibCcMastercard,
//   cibCcVisa,
//   cibCcStripe,
//   cibCcPaypal,
//   cibCcApplePay,
//   cibCcAmex,
// } from '@coreui/icons'

/* FLAGS */
import {
  cifCo,
  cifMx,
  cifEs,
} from '@coreui/icons'

/* ICONS */
import {
  cilX,
  cilXCircle,
  cilClock,
  cilSpeedometer,
  cilUser,
  cilLockLocked,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilArrowLeft,
  cilChevronBottom,
  cilChevronRight,
  cilChevronTop,
  cilChevronLeft,
  cilChevronCircleRightAlt,
  cilBasket,
  cilBell,
  cilCalendar,
  cilChartPie,
  cilCheck,
  cilDiamond,
  cilDollar,
  cilList,
  cilMenu,
  cilStar,
  cilSortAscending,
  cilMoney,
  cilGift,
  cilHeart,
  cilSearch,
  cilActionUndo,
  cilContrast,
  cilSun,
  cilMoon,
  cilCreditCard,
  cilExternalLink,
  cilInfo,
  cilCircle,
  cilReload,
  cilCheckCircle,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilX,
    cilXCircle,
    cilClock,
    cilSpeedometer,
    cilUser,
    cilLockLocked,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilArrowLeft,
    cilChevronBottom,
    cilChevronRight,
    cilChevronTop,
    cilChevronLeft,
    cilChevronCircleRightAlt,
    cilBasket,
    cilBell,
    cilCalendar,
    cilChartPie,
    cilCheck,
    cilDiamond,
    cilDollar,
    cilList,
    cilMenu,
    cilStar,
    cilSortAscending,
    cilMoney,
    cilGift,
    cilHeart,
    cilSearch,
    cilActionUndo,
    cilContrast,
    cilSun,
    cilMoon,
    cilCreditCard,
    cilExternalLink,
    cilInfo,
    cilCircle,
    cilReload,
    cilCheckCircle,
  },
  // FLAGS
  {
    cifCo,
    cifMx,
    cifEs,
  },
  // BRANDS
  // {
  //   cibFacebook,
  //   cibTwitter,
  //   cibLinkedin,
  //   cibFlickr,
  //   cibTumblr,
  //   cibXing,
  //   cibGithub,
  //   cibGoogle,
  //   cibStackoverflow,
  //   cibYoutube,
  //   cibDribbble,
  //   cibInstagram,
  //   cibPinterest,
  //   cibVk,
  //   cibYahoo,
  //   cibBehance,
  //   cibReddit,
  //   cibVimeo,
  //   cibCcMastercard,
  //   cibCcVisa,
  //   cibCcStripe,
  //   cibCcPaypal,
  //   cibCcApplePay,
  //   cibCcAmex,
  // },
)
