<template>
  <div v-if="loading.visible" class="loading-overlay">
    <CSpinner color="secondary"/>
  </div>
</template>

<script setup>
  import { useLoadingStore } from '@/stores/loading'
  const loading = useLoadingStore()
</script>

<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
</style>
