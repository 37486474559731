<template>
  <CBreadcrumb class="my-0">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const router = useRouter()

  const breadcrumbs = ref()

  const addParentItem = (currentRoute, breadCrumbList) => {
    if (!currentRoute?.meta?.parentName) {
      return
    }
    const allRoutes = router.getRoutes()
    const parentRoute = allRoutes.find(v => v.name === currentRoute.meta.parentName)
    if (parentRoute) {
      const index = breadCrumbList.findIndex(v => v.name === currentRoute.name)
      breadCrumbList.splice(index, 0, {
        active: false,
        name: parentRoute.meta && parentRoute.meta.label ? t(parentRoute.meta.label) : parentRoute.name,
        path: `${router.options.history.base}${parentRoute.path}`,
      })

      if (parentRoute?.meta?.parentName) {
        addParentItem(parentRoute, breadCrumbList)
      }
    }
  }

  const getBreadcrumbs = () => {
    const breadCrumbList = router.currentRoute.value.matched.map(route => {
      return {
        active: route.name === router.currentRoute.value.name,
        name: route.meta && route.meta.label ? t(route.meta.label) : route.name,
        path: `${router.options.history.base}${route.path}`,
      }
    })

    if (router.currentRoute.value?.meta?.parentName) {
      addParentItem(router.currentRoute.value, breadCrumbList)
    }

    return breadCrumbList
  }

  router.afterEach(() => {
    breadcrumbs.value = getBreadcrumbs()
  })

  onMounted(() => {
    breadcrumbs.value = getBreadcrumbs()
  })
</script>
