export default {
  es: {
    general: {
      badges: {
        soon: 'Próximamente'
      }
    },
    auth: {
      login: {
        title: 'Iniciar sesión',
        subtitle: 'Ingresa a tu cuenta',
        forgot: '¿Olvidaste tu contraseña?',
        button: '@:auth.login.title',
        remember: 'Recordar en este navegador',
        link: '¿Ya estás registrado?',
        notification: {
          title: 'Error'
        }
      },
      signup: {
        title: 'Regístrate',
        subtitle: 'Regístrate para obtener información sobre los productos ganadores para dropshipping',
        link: '¡Regístrate ahora!',
        button: 'Suscribirse',
        description: '¿No tienes una cuenta? Regístrate y obtén información sobre los mejores productos en el mercado.',
        oldPassword: 'Contraseña anterior',
        newPassword: 'Nueva contraseña',
        repeatPassword: 'Repetir contraseña',
        back: 'Regresar',
        emailAlreadyExists: 'Usuario ya existe',
        privacy: 'No almacenamos tus datos de pago. Todos los pagos se realizan a través de la plataforma de MercadoPago con todos los estándares de seguridad. Puedes anular tu suscripción cuando desees.',
      },
      recovery: {
        title: 'Recupera tu contraseña',
        subtitle: 'Escribe tu correo y te enviaremos un enlace de recuperación',
        button: 'Enviar correo',
        notification: {
          title: 'Correo enviado',
          content: 'Revisa tu correo para recuperar tu contraseña'
        }
      },
      email: 'Correo electrónico',
      password: 'Contraseña'
    },
    premium: {
      link: 'Hazte premium',
      signup: {
        total: 'Total a pagar',
        plan: {
          description: 'Pago mensual con renovación automática',
          details: {
            title: 'Lo que obtienes con el plan:',
          }
        }
      },
      payment: {
        cardNumber: 'Número de tarjeta de crédito',
        cardholderName: 'Nombre en tarjeta',
        expirationDate: 'Fecha de expiración',
        month: 'MM',
        year: 'AA',
        securityCode: 'CVV',
        messageList: {
          invalidCard: 'Tarjeta de crédito no válida',
        }
      },
      from: 'Desde',
      frequency: {
        daily: 'diario',
        weekly: 'semanal',
        monthly: 'mensual',
        yearly: 'anual',
      },
      renew: {
        title: 'Renueva tu suscripción',
        description: 'Para continuar disfrutando los beneficios de nuestros servicios, por favor asegúrate de renovar tu suscripción.',
        nextStep: 'Para hacerlo, puedes ir al siguiente enlace:',
        button: 'Renovar mi suscripción',
        notAccount: '¿No es tu cuenta?',
        login: 'Inicia nuevamente'
      }
    },
    footer: {
      powered: 'Desarrollado por',
      owner: 'Camilo Orozco'
    },
    header: {
      dropdown: {
        shortcuts: 'Atajos',
        watchlist: 'Lista de seguimiento',
        settings: 'Configuración',
        myAccount: 'Mi cuenta',
        billing: 'Facturación',
        logout: 'Cerrar sesión'
      }
    },
    routes: {
      breadcrumbs: {
        renew: 'Renovación',
        productDetail: 'Detalle del producto',
        dashboard: 'Productos',
        tracing: 'Seguimiento',
      },
    },
    dashboard: {
      link: 'Ir al tablero',
      filter: {
        searchBy: 'Buscar por ID, Nombre',
        country: 'País',
        category: 'Categoría',
        allSuppliers: 'Todos los proveedores',
        supplierVerified: 'Proveedor verificado',
        supplierPrice: 'Precio del proveedor',
        salesFilter: 'Filtro de ventas',
        salesDays: '{days} días',
        sales: 'Ventas',
        billing: 'Facturación',
        stock: 'Inventario',
        min: 'Mín',
        max: 'Máx',
        pagination: {
          previous: 'Anterior',
          next: 'Siguiente',
          itemsPerPage: 'Elementos por página',
          showItems: 'Mostrando {itemsRange} elementos de {itemsTotal}'
        },
        values: {
          verified: 'Verificados',
          premium: 'Premium',
        },
        actions: {
          filter: 'Filtrar',
          reset: 'Limpiar',
        },
      },
      list: {
        header: {
          product: 'Producto',
          id: 'ID',
          supplierPrice: 'Precio del proveedor',
          suggestedPrice: 'Precio sugerido',
          earnings: 'Ganancias',
          estimatedEarnings: 'Ganacias estimadas',
          statistics: 'Estadísticas ({days} días)',
          days: '({days} días)',
          sales: 'Ventas',
          billing: 'Facturación',
          information: 'Información sobre las ventas',
          tracing: 'Favorito',
        },
        body: {
          stock: '@:dashboard.filter.stock',
          supplier: 'Proveedor',
          detail: 'Más info',
          chart: {
            lastDays: 'Últimos {days} días',
            sales: '@:dashboard.filter.sales',
            stock: '@:dashboard.filter.stock'
          }
        },
        actions: {
          follow: 'Seguir',
          following: 'Siguiendo',
        },
        notices: {
          notFound: 'No se encontraron productos'
        }
      },
      detail: {
        reference: 'Referencia',
        seeIn: 'Ver en {marketplace}',
        supplierPrice: '@:dashboard.list.header.supplierPrice',
        suggestedPrice: '@:dashboard.list.header.suggestedPrice',
        stock: '@:dashboard.filter.stock',
        sales: '@:dashboard.filter.sales',
        billing: '@:dashboard.filter.billing',
        estimatedEarnings: '@:dashboard.list.header.estimatedEarnings',
        similarProducts: 'Productos similares',
        lastUpdate: 'Última actualización',
        description: 'Descripción del producto suministrada por el proveedor',
      }
    },
    notifications: {
      titles: {
        error: 'Error',
        success: 'Correcto',
      },
      content: {
        unknow: 'Error desconocido, contacte al administrador',
      },
      notices: {
        soon: {
          soon: 'estará disponible pronto',
          marketplace: 'Marketplace @:notifications.notices.soon.soon',
        },
      },
    },
  },
  en: {
    general: {
      badges: {
        soon: 'Soon',
      }
    },
    auth: {
      login: {
        title: 'Login',
        subtitle: 'Sign In to your account',
        forgot: 'Forgot password?',
        button: '@:auth.login.title',
        remember: 'Remember in this browser',
        link: 'Already registered?',
        notification: {
          title: 'Error',
        },
      },
      signup: {
        title: 'Sign up',
        subtitle: 'Register to get information about the winner products for dropshipping',
        link: 'Register Now!',
        button: 'Create Account',
        description: 'Don\'t have an account? signup and get information about the best products in market.',
        oldPassword: 'Old password',
        newPassword: 'New password',
        repeatPassword: 'Repeat password',
        back: 'Back',
        emailAlreadyExists: 'User already exists',
        privacy: 'We do not store your payment information. All payments are made through the MercadoPago platform with all security standards. You can cancel your subscription whenever you want.',
      },
      recovery: {
        title: 'Recover your password',
        subtitle: 'Type your email and will send a mail with a recover link',
        button: 'Send mail',
        notification: {
          title: 'Mail was sent',
          content: 'Check your email for recover your password',
        }
      },
      email: 'E-mail',
      password: 'Password'
    },
    premium: {
      link: 'Go premium',
      signup: {
        total: 'Total to pay',
        plan: {
          description: 'Monthly payment with automatic renewal',
          details: {
            title: 'What you get with the plan:',
          }
        }
      },
      payment: {
        cardNumber: 'Credit card number',
        cardholderName: 'Card holdername',
        expirationDate: 'Expiration date',
        month: 'MM',
        year: 'YY',
        securityCode: 'CVV',
        messageList: {
          invalidCard: 'Number card is invalid',
        }
      },
      from: 'From',
      frequency: {
        daily: 'daily',
        weekly: 'weekly',
        monthly: 'monthly',
        yearly: 'yearly',
      },
      renew: {
        title: 'Renew your subscription',
        description: 'To continue enjoying the benefits of our services, please be sure to renew your subscription.',
        nextStep: 'To do so, you can go to the following link:',
        button: 'Renew my subscription',
        notAccount: 'Nor your account?',
        login: 'Sign in again'
      }
    },
    footer: {
      powered: 'Powered by',
      owner: 'Camilo Orozco',
    },
    header: {
      dropdown: {
        shortcuts: 'Shortcuts',
        watchlist: 'Watchlist',
        settings: 'Settings',
        myAccount: 'My account',
        billing: 'Billing',
        logout: 'Logout',
      }
    },
    dashboard: {
      link: 'Go to dashboard',
      filter: {
        searchBy: 'Search by ID, Name',
        country: 'Country',
        category: 'Category',
        allSuppliers: 'All suppliers',
        supplierVerified: 'Verified supplier',
        supplierPrice: 'Supplier price',
        salesFilter: 'Sales filter',
        salesDays: '{days} days',
        sales: 'Sales',
        billing: 'Billing',
        stock: 'Stock',
        min: 'Min',
        max: 'Max',
        pagination: {
          previous: 'Prev',
          next: 'Next',
          itemsPerPage: 'Items per page',
          showItems: 'Showing {itemsRange} items of {itemsTotal}',
        },
        values: {
          verified: 'Verified',
          premium: 'Premium',
        },
        actions: {
          filter: 'Filter',
          reset: 'Clean',
        },
      },
      list: {
        header: {
          product: 'Product',
          id: 'ID',
          supplierPrice: 'Supplier price',
          suggestedPrice: 'Suggested price',
          earnings: 'Earnings',
          estimatedEarnings: 'Estimated earnings',
          statistics: 'Statistics ({days} days)',
          days: '({days} days)',
          sales: 'Sales',
          billing: 'Billing',
          information: 'Sales information',
          tracing: 'Favorite',
        },
        body: {
          stock: '@:dashboard.filter.stock',
          supplier: 'Supplier',
          chart: {
            lastDays: 'Last {days} days',
            sales: '@:dashboard.filter.sales',
            stock: '@:dashboard.filter.stock',
          }
        },
        actions: {
          follow: 'Follow',
          following: 'Following',
        },
        notices: {
          notFound: 'No products found'
        }
      },
    },
    notifications: {
      titles: {
        error: 'Error',
        success: 'Success',
      },
      content: {
        unknow: 'Unknow error, contact the admin',
      },
      notices: {
        soon: {
          soon: 'comming soon be available',
          marketplace: 'Marketplace @:notifications.notices.soon.soon',
        },
      },
    }
  }
}
