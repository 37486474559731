import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', () => {
  const visible = ref(false)

  const onLoading = () => {
    visible.value = true
  }

  const offLoading = () => {
    visible.value = false
  }

  return { visible, onLoading, offLoading }
})
