// import { h, resolveComponent } from 'vue'
import { ref } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { useLoadingStore } from '@/stores/loading'
import api from '@/services/api'
import DefaultLayout from '@/layouts/DefaultLayout'

/** AUTH CONTROL **/
import FirebaseAuth, { onAuthStateChanged } from '@/firebase/Auth'
const user = ref(null)
const finish = ref(false)

/** ROUTES **/
const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/landing/Landing.vue'),
  },
  {
    path: '/dashboard',
    name: 'Home',
    meta: { requiresAuth: true },
    component: DefaultLayout,
    redirect: '/dashboard/products',
    children: [
      {
        path: 'products',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        meta: {
          label: 'routes.breadcrumbs.dashboard'
        },
      },
      {
        path: 'product/:marketplaceName/:isoCode/:productId',
        name: 'ProductDetail',
        component: () => import('@/views/dashboard/ProductDetail.vue'),
        props: true,
        meta: {
          parentName: 'Dashboard',
          label: 'routes.breadcrumbs.productDetail'
        },
      },
      {
        path: 'tracing',
        name: 'Tracing',
        component: () => import('@/views/dashboard/Tracing.vue'),
        meta: {
          label: 'routes.breadcrumbs.tracing'
        },
      },
      {
        path: 'renew',
        name: 'Renew',
        component: () => import('@/views/auth/Renew'),
        meta: {
          label: 'routes.breadcrumbs.renew'
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/Register'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

const validateAccount = () => {
  return new Promise((resolve, reject) => {
    try {
      api.get('/auth/validate').then(response => {
        resolve(response.data.valid)
      }).catch(err => {
        reject(err)
      })
    } catch (err) {
      reject(err)
    }
  })
}

// Guard de navegación
router.beforeEach((to, from, next) => {
  const loading = useLoadingStore()
  loading.onLoading()

  if (to.meta.requiresAuth && !user.value) {
    // next({ name: 'Login' }) // Do nothing now
  } else if (!to.meta.requiresAuth && user.value) {
    next()
  } else if (to.meta.requiresAuth && to.name !== 'Renew') {
    validateAccount().then(valid => {
      if (valid) {
        next()
      } else {
        next({ name: 'Renew' })
      }
    })
  } else {
    next()
  }
})

router.afterEach(() => {
  onLoadRoutes()
})

const onLoadRoutes = () => {
  if (finish.value) {
    const loading = useLoadingStore()
    loading.offLoading()
  }
}

/** MANAGE LOGOUT AND LOGIN **/
onAuthStateChanged(FirebaseAuth, async firebaseUser => {
  user.value = firebaseUser
  if (firebaseUser) {
    // Validate user status
    validateAccount().then(valid => {
      if (valid) {
        router.push({ name: 'Dashboard' })
        finish.value = true
      } else {
        // Not valid, show renew component
        router.push({ name: 'Renew' })
        finish.value = true
      }
    }).catch(error => {
      console.log('ERRROR', error)
      finish.value = true
    })
  } else {
    if (router.currentRoute.value.name !== 'Landing') {
      router.push({ name: 'Landing' })
    }
    finish.value = true
  }
  onLoadRoutes()
})

export default router
