<template>
  <Loading />
  <router-view />
</template>

<script setup>
import { onBeforeMount } from 'vue'
// Theme
import { useColorModes } from '@coreui/vue'
import { useThemeStore } from '@/stores/theme.js'
// Loading
import Loading from '@/components/Loading'

/** THEME CONSTS **/
const { isColorModeSet, setColorMode } = useColorModes('coreui-free-vue-admin-template-theme')
const currentTheme = useThemeStore()

/** HOOKS **/
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.href.split('?')[1])
  let theme = urlParams.get('theme')

  if (theme !== null && theme.match(/^[A-Za-z0-9\s]+/)) {
    theme = theme.match(/^[A-Za-z0-9\s]+/)[0]
  }

  if (theme) {
    setColorMode(theme)
    return
  }

  if (isColorModeSet()) {
    return
  }

  setColorMode(currentTheme.theme)
})

</script>

<style lang="scss">
  @import '@/assets/styles/default';
  @import 'vue-skeletor/dist/vue-skeletor.css';
</style>
